import Vue from 'vue'
// @ts-ignore
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
// @ts-ignore
import Vuelidate from 'vuelidate'
// @ts-ignore
import wysiwyg from "vue-wysiwyg"
// @ts-ignore
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import Http from "@/services/Http";

Vue.use(Buefy);

let baseUrl;
baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
if (process.env.NODE_ENV === 'local') {
    baseUrl = "http://localhost:8080";
}

Vue.use(VueAxios, axios.create({baseURL: baseUrl}));
Http.axios = Vue.axios;
Vue.use(Vuelidate);

Vue.use(wysiwyg, {
    hideModules: {"image": true}
});

Vue.config.productionTip = false;

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
