<template>
	<content-wrapper title="General Info">
		<div class="container">
			<files title="Legal Documents" file-type="LegalDocument" :reverse="true" :logged-in="$parent.loggedIn"></files>
		</div>

		<div class="container">
			<files title="Maps and Directory" file-type="MapOrDirectory" :logged-in="$parent.loggedIn"></files>
		</div>

		<div class="container">
			<files title="Land Records" file-type="LandRecord" :logged-in="$parent.loggedIn"></files>
		</div>

		<div class="container">
			<files title="Miscellaneous" file-type="Miscellaneous" :logged-in="$parent.loggedIn"></files>
		</div>
	</content-wrapper>
</template>

<script>
    import ContentWrapper from "@/components/ContentWrapper";
    import Links from "@/components/Links";
    import Files from "@/components/Files";

    export default {
        name: "Info",
        components: {ContentWrapper, Links, Files},
        data() {
            return {}
        },
        methods: {}
    }
</script>

<style lang="scss" scoped>
	.container {
		margin-bottom: 24px;
	}
</style>
