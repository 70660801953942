<template>
	<content-wrapper title="News">
		<files :file-types="['News']" :logged-in="$parent.loggedIn"></files>
		<h2>Archive</h2>
		<b-button data-test="show-archive-button" @click="showArchive = true" v-if="!showArchive">Show Archive</b-button>
		<b-collapse :open.sync="showArchive">
			<files  :file-types="['News', 'Newsletter']" :show-archive="true" :logged-in="$parent.loggedIn"></files>
		</b-collapse>
	</content-wrapper>
</template>

<script>
    import ContentWrapper from "@/components/ContentWrapper";
    import Files from "@/components/Files";

    export default {
        name: "News",
        components: {ContentWrapper, Files},
        methods: {},
        data() {
            return {
                showArchive: false
            }
        }
    }
</script>

<style lang="scss" scoped>
	img {
		max-width: 500px;
		border-radius: 10px;
		@include mobile {
			max-width: 100%;
		}
	}
</style>
