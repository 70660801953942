<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <div v-if="editing">
      <h3>Add Link</h3>
      <b-field grouped group-multiline>
        <b-field label="Link Name"
                 :type="getType($v.link.name)"
                 :message="getMessage($v.link.name)"
                 expanded>
          <b-input v-model="$v.link.name.$model" data-test="name-input"></b-input>
        </b-field>
        <b-field label="Link Url"
                 :type="getType($v.link.url)"
                 :message="getMessage($v.link.url)"
                 expanded>
          <b-input v-model="$v.link.url.$model" data-test="url-input"></b-input>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field>
          <b-button data-test="links-save-button" @click="saveLink" type="is-black" :loading="saving">Save</b-button>
        </b-field>
        <b-field>
          <b-button data-test="links-cancel-button" @click="clearLink">Cancel</b-button>
        </b-field>
        <b-field v-if="link.id">
          <b-button data-test="links-delete-button" @click="deleteLink" type="is-danger" :loading="saving">Delete
          </b-button>
        </b-field>
      </b-field>
    </div>
    <div v-else-if="loggedIn">
      <b-button data-test="links-add-button" @click="newLink" type="is-black">Add Link</b-button>
    </div>
    <div v-if="links.length > 0 && !editing" data-test="links-links">
      <ul>
        <li v-for="link in links">
          <a data-test="links-edit-button" :href="link.url" target="_blank">{{ link.name }}</a> <span class="edit"
                                                                                                      v-if="loggedIn"
                                                                                                      @click="editLink(link)"><b-icon
            icon="square-edit-outline"/></span>
        </li>
      </ul>
    </div>
    <div v-else-if="loading" class="has-margin-top">
      Loading...
    </div>
    <div v-else-if="!editing" class="has-margin-top">
      No links yet.
    </div>
  </div>
</template>

<script>
import {required} from "vuelidate/lib/validators";

export default {
  name: "Links",
  props: {
    "title": {type: String},
    "loggedIn": {type: Boolean}
  },
  data() {
    return {
      links: [],
      link: {
        name: "",
        url: ""
      },
      loading: true,
      editing: false,
      saving: false
    }
  },
  mounted() {
    this.loadLinks();
  },
  validations: {
    link: {
      name: {
        required
      },
      url: {
        required
      }
    }
  },
  methods: {
    getType(model) {
      return model.$error ? "is-danger" : "";
    },
    getMessage(model) {
      if (model.$error && model.required === false) {
        return "This field is required";
      } else if (model.$error) {
        return "Value invalid";
      }
    },
    loadLinks() {
      this.axios.get("/api/links")
          .then(response => {
            this.links = response.data;
          })
          .finally(() => {
            this.loading = false;
          });
    },
    newLink() {
      this.editLink({});
    },
    editLink(link) {
      this.link = Object.assign(this.link, link);
      this.editing = true;
    },
    clearLink() {
      this.editing = false;
    },
    deleteLink() {
      if (!this.saving) {
        const result = window.confirm(`Are you sure you want to delete ${this.link.name}?`);
        if (result) {
          this.saving = true;
          this.axios.delete(`/api/links/${this.link.id}`)
              .then(response => {
                this.clearLink();
                this.loadLinks();
              })
              .finally(() => {
                this.saving = false;
              });
        }
      }
    },
    saveLink() {
      this.$v.link.$reset();
      this.$v.link.$touch();
      if (!this.saving && !this.$v.link.$anyError) {
        this.saving = true;
        this.axios.post("/api/links", this.link)
            .then(response => {
              this.clearLink();
              this.loadLinks();
            })
            .finally(() => {
              this.saving = false;
            });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.has-margin-top {
  margin-top: 24px;
}

.error {
  color: red;
  font-size: 14px;
}
</style>
