import 'core-js/proposals/url'

export default class Http {

    private static _axios: any;

    static set axios(value: any) {
        this._axios = value;
    }

    static get(path: string, params: { [id: string]: any }) {
        const url = new URL(path, this._axios.defaults.baseURL);
        for (const key in params) {
            const value = params[key];
            if (value !== null) {
                url.searchParams.set(key, value)
            }
        }
        return this._axios.get(url.toString())
    }

}