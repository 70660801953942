<template>
  <content-wrapper title="Private Files">
    <files :show-type="true" :file-types="['Private']" :logged-in="$parent.loggedIn"></files>
  </content-wrapper>
</template>

<script>
import Files from "@/components/Files";
import {required} from "vuelidate/lib/validators";
import ContentWrapper from "@/components/ContentWrapper";

export default {
  name: "Minutes",
  components: {Files, ContentWrapper},
  validations: {
    event: {
      name: {
        required
      },
      date: {
        required
      }
    }
  },
  methods: {
    getType(model) {
      return model.$error ? "is-danger" : "";
    },
    getMessage(model) {
      if (model.$error && model.required === false) {
        return "This field is required";
      } else if (model.$error && model.email === false) {
        return "Email invalid";
      } else if (model.$error) {
        return "Value invalid";
      }
    }
  },
  data() {
    return {
      saving: false,
      loading: true,
      editing: false,
      showArchive: false
    }
  },
  mounted() {
    this.$parent.onLogin(isLoggedIn => {
      if (!isLoggedIn) this.$router.replace({path: '/'});
    })
  }
}
</script>

<style lang="scss" scoped>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";

.edit {
  cursor: pointer;
  color: #1d72aa;
}

.has-margin-top {
  margin-top: 10px;
}

.error {
  color: red;
  font-size: 14px;
}

.archive {
  margin-top: 20px;
}
</style>
