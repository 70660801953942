<template>
	<div class="container">
		<div class="columns">
			<div class="column">
				<h1>Login</h1>
				<div class="has-text-danger" v-if="hasError()">Incorrect Email/Password combination please try again</div>
				<form name="login" class="login-form" action="/login" method="POST">
					<b-field label="Email" :type="getType()">
						<b-input data-test="login-username" name="email" v-model="username"></b-input>
					</b-field>

					<b-field label="Password" :type="getType()">
						<b-input data-test="login-password" name="password" type="password" v-model="password" password-reveal></b-input>
					</b-field>

					<b-field>
						<b-button data-test="login-button" type="is-black" native-type="submit" value="Login">Login</b-button>
					</b-field>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "Login",
        data() {
            return {
                username: "",
                password: ""
            }
        },
        methods: {
            hasError() {
                return !!this.$route.query.error;
            },
            getType() {
                return this.hasError() ? "is-danger" : null;
            }
        }
    }
</script>

<style lang="scss" scoped>
	.login-form {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 50px;
		@include mobile {
			max-width: 100%;
		}
	}
</style>
