<template>
  <div class="container">
    <h2 v-if="title">{{ title }}</h2>
    <div v-if="editing" class="form">
      <h3>Add File</h3>
      <b-field grouped group-multiline>
        <b-field label="File Name"
                 :type="getType($v.file.name)"
                 :message="getMessage($v.file.name)"
                 expanded>
          <b-input data-test="file-name-input" v-model="$v.file.name.$model"></b-input>
        </b-field>
        <b-field label="Date" expanded>
          <b-datepicker data-test="date-input" v-model="file.date" placeholder="Click to select..."></b-datepicker>
        </b-field>
      </b-field>
      <b-field>
        <b-field label="File Description" expanded>
          <b-input type="textarea" data-test="description-input" v-model="file.description"></b-input>
        </b-field>
      </b-field>
      <b-field>
        <b-field label="Summary" expanded>
          <b-input type="textarea" data-test="summary-input" v-model="file.summary"></b-input>
        </b-field>
      </b-field>
      <b-field label="Type">
        <b-select v-model="file.type" data-test="type-input">
          <option v-for="option in this.availableFileTypes" :value="option" :key="option">
            {{ option }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-checkbox v-model="file.archive">Archive</b-checkbox>
      </b-field>
      <b-field label="File"
               :type="getType($v.file.name)"
               :message="getMessage($v.file.name)">
        <b-upload v-model="file.file" data-test="file-input">
          <b-button tag="a" type="is-black" icon-left="upload">Click to upload</b-button>
        </b-upload>
      </b-field>
      <b-field grouped group-multiline>
        <b-field>
          <b-button icon-left="content-save" data-test="files-save-button" @click="saveFile" type="is-black"
                    :loading="saving">Save
          </b-button>
        </b-field>
        <b-field>
          <b-button icon-left="cancel" data-test="files-cancel-button" @click="clearFile">Cancel</b-button>
        </b-field>
        <b-field v-if="file.id">
          <b-button icon-left="delete" data-test="files-delete-button" @click="deleteFile" type="is-danger"
                    :loading="saving">Delete
          </b-button>
        </b-field>
        <b-field>
          <b-button icon-left="auto-fix" data-test="files-cancel-button" @click="generateSummary" :loading="saving">
            Summarize
          </b-button>
        </b-field>
      </b-field>
    </div>
    <div v-else-if="loggedIn">
      <div class="buttons">
        <b-button data-test="files-add-button" @click="newFile" type="is-black">Add File</b-button>
      </div>
    </div>

    <div v-if="this.files.length > 0 && !editing" data-test="files-list">
      <ul>
        <li v-for="file in files">
          <file-item :editable="true" @edit="editFile(file)" :file="file" :logged-in="loggedIn"></file-item>
        </li>
      </ul>
    </div>
    <div v-else-if="loading" class="has-margin-top">
      Loading...
    </div>
    <div v-else-if="!editing" class="has-margin-top empty" data-test="files-none">
      No files yet.
    </div>
  </div>
</template>

<script>
import {required, requiredIf} from 'vuelidate/lib/validators';
import Http from "@/services/Http";
import FileItem from "@/components/FileItem.vue";

export default {
  name: "Files",
  components: {
    FileItem
  },
  props: {
    title: {type: String},
    loggedIn: {type: Boolean},
    reverse: {type: Boolean, default: false},
    showArchive: {type: Boolean, default: false},
    fileType: {type: String, default: ""},
    fileTypes: {type: Array},
    showType: {type: Boolean, default: false}
  },
  data() {
    return {
      files: [],
      file: {
        name: "",
        dataFilename: ""
      },
      loading: true,
      editing: false,
      saving: false
    }
  },
  mounted() {
    this.loadFiles();
  },

  validations: {
    file: {
      name: {
        required
      },
      dataFilename: {
        requiredIf: requiredIf((file) => file.id === null)
      }
    }
  },
  watch: {
    "file.file": async function () {
      this.file.dataContentType = this.file.file.type;
      this.file.dataFilename = this.file.file.name;
      this.file.data = await this.toBase64(this.file.file);
      this.$v.file.dataFilename.$touch();
    }
  },
  computed: {
    availableFileTypes() {
      return this.fileTypes ? this.fileTypes : [this.fileType];
    },
    showSummaries() {
      return this.loggedIn;
    }
  },
  methods: {
    getType(model) {
      return model.$error ? "is-danger" : "";
    },
    getMessage(model) {
      if (model.$error && model.required === false) {
        return "This field is required";
      } else if (model.$error && model.email === false) {
        return "Email invalid";
      } else if (model.$error) {
        return "Value invalid";
      }
    },
    loadFiles() {
      const params = {
        "showArchive": this.showArchive,
        "types": this.availableFileTypes.concat(',')
      }

      Http.get("/api/files", params)
        .then(response => {
          response.data.forEach(next => {
            next.date = new Date(next.date.substr(0, 23) + "Z");
          });

          this.files = response.data
            .sort((a, b) => {
              const diff = b - a;
              return diff * (this.reverse ? -1 : 1);
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newFile() {
      const file = {};
      if (this.fileType !== "") file.type = this.fileType;
      file.archive = this.showArchive;
      this.editFile(file);
    },
    editFile(file) {
      if (file && !file.type) file.type = this.availableFileTypes[0];
      this.file = Object.assign(this.file, file);
      this.editing = true;
    },
    generateSummary() {
      this.saving = true;
      this.loading = true;
      this.axios.post(`/api/files/${this.file.id}/summary`)
        .then(response => {
          this.clearFile()
          this.loadFiles()
        })
        .finally(() => {
          this.loading = false;
          this.saving = false;
        });
    },
    clearFile() {
      this.editing = false;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    deleteFile() {
      if (!this.saving) {
        const result = window.confirm(`Are you sure you want to delete ${this.file.name}?`);
        if (result) {
          this.saving = true;
          this.axios.delete(`/api/files/${this.file.id}`)
            .then(response => {
              this.clearFile();
              this.loadFiles();
            })
            .finally(() => {
              this.saving = false;
            });
        }
      }
    },
    saveFile() {
      this.$v.$reset();
      this.$v.$touch();
      if (!this.saving && !this.$v.$anyError) {
        this.saving = true;
        this.axios.post("/api/files", this.file)
          .then(response => {
            this.clearFile();
            this.loadFiles();
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.has-margin-top {
  margin-top: 24px;
}

.empty {
  margin-bottom: 10px;
}

</style>
