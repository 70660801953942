<template>
	<content-wrapper title="Contact">
		<h2>Board Members</h2>

		<p><strong>President:</strong> Nate Riesen</p>
		<p><a href="mailto:president@stonebrookhoa.org">president@stonebrookhoa.org</a></p>

<!--		<p><strong>Vice President:</strong> Michael Polley</p>-->
<!--    <p><a href="mailto:vicepresident@stonebrookhoa.org">vicepresident@stonebrookhoa.org</a></p>-->

		<p><strong>Treasurer:</strong> Michael Polley</p>
		<p><a href="mailto:treasurer@stonebrookhoa.org">treasurer@stonebrookhoa.org</a></p>

		<p><strong>Secretary:</strong> Dave Suehs</p>
		<p><a href="mailto:secretary@stonebrookhoa.org">secretary@stonebrookhoa.org</a></p>

    <p><strong>Conservancy Coordinator:</strong> Jeannie Bettesworth</p>

		<p><strong>Committee Members</strong></p>
		<p><a href="mailto:committeemembers@stonebrookhoa.org">committeemembers@stonebrookhoa.org</a></p>

		<p><strong>Architectural</strong></p>
		<p><a href="mailto:architectural@stonebrookhoa.org">architectural@stonebrookhoa.org</a></p>

		<p><strong>Outlot</strong></p>
		<p><a href="mailto:outlot@stonebrookhoa.org">outlot@stonebrookhoa.org</a></p>

		<p><strong>Social</strong></p>
		<p><a href="mailto:social@stonebrookhoa.org">social@stonebrookhoa.org</a></p>

		<p>For questions, concerns or closing letters contact a board member directly</p>

		<h3>Stonebrook Homeowners Discussion Group</h3>
		<p><a href="mailto:stonebrook-estates-homeowners@googlegroups.com">stonebrook-estates-homeowners@googlegroups.com</a></p>
    <p>To add yourself to this list go to: <a href="https://groups.google.com/g/stonebrookestatescommunity">Stonebrook Estate Community</a></p>
	</content-wrapper>
</template>

<script>
    import ContentWrapper from "@/components/ContentWrapper";

    export default {
        name: "Contact",
        components: {ContentWrapper}
    }
</script>

<style scoped>

</style>
