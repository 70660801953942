<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <h1>Gallery Administration</h1>

        <div v-if="showImageForm" class="form has-margin-bottom">
          <b-field grouped group-multiline>
            <b-field :type="errorType" :message="errorMessage">
              <b-upload v-model="file" data-test="file-input">
                <b-button tag="a" type="is-black" :loading="saving || loading">
                  <b-icon icon="upload"/>
                  <span class="has-padding-left">Click to upload</span>
                </b-button>
              </b-upload>
            </b-field>
            <b-field>
              <b-button @click="clearImage">Cancel</b-button>
            </b-field>
          </b-field>
        </div>
        <div v-else class="has-margin-bottom">
          <b-field grouped group-multiline>
            <b-field>
              <b-button @click="newImage" type="is-black">Add Image</b-button>
            </b-field>
            <b-field>
              <b-button @click="clearThumbnails" type="is-danger" :loading="saving || loading">Clear Thumbnails
              </b-button>
            </b-field>
            <b-field>
              <b-button @click="clearCache" type="is-warning" :loading="saving || loading">Clear Cache
              </b-button>
            </b-field>
          </b-field>
        </div>
        <div class="container">
          <table class="table is-striped is-bordered is-hoverable">
            <thead>
            <tr>
              <th>Image</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="image in images" class="has-text-centered">
              <td><img alt="gallery-image" class="image" :src="image.url"/></td>
              <td>{{ image.name }}</td>
              <td>{{ image.description }}</td>
              <th>
                <b-button @click="deleteImage(image.name)" :loading="saving || loading">Delete</b-button>
                <b-button @click="generateDescription(image.name)" :loading="saving || loading">Generate Description
                </b-button>
              </th>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Gallery",
  mounted() {
    this.$parent.onLogin(isLoggedIn => {
      if (!isLoggedIn) this.$router.replace({path: '/'});
    })
    this.loadImages();
  },
  data() {
    return {
      showImageForm: false,
      images: [],
      image: null,
      file: null,
      loading: false,
      saving: false,
      errorMessage: null
    }
  },
  computed: {
    errorType() {
      return this.errorMessage ? "is-danger" : ""
    }
  },
  watch: {
    file: async function () {
      if (this.file) {
        this.image = {
          dataContentType: this.file.type,
          dataFilename: this.file.name,
          data: await this.toBase64(this.file)
        };
        this.saveImage()
      }
    }
  },
  methods: {
    loadImages() {
      this.loading = true
      this.axios.get("/api/gallery")
        .then(response => {
          this.loading = false;
          this.images = response.data;
        })
    },
    newImage() {
      this.showImageForm = true
    },
    clearImage() {
      this.showImageForm = false
      this.image = null
      this.file = null
      this.errorMessage = null
    },
    saveImage() {
      if (!this.saving && this.file && this.image) {
        this.saving = true
        this.axios.post("/api/gallery", this.image)
          .then(_ => {
            this.loadImages()
            this.clearImage()
          })
          .catch(error => {
            this.errorMessage = error.response.data.message
          })
          .finally(() => {
            this.saving = false
          });
      }
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    deleteImage(name) {
      if (!this.saving) {
        const result = window.confirm(`Are you sure you want to delete ${name}?`);
        if (result) {
          this.saving = true;
          this.axios.delete(`/api/gallery/image/${name}`)
            .then(_ => this.loadImages())
            .finally(() => this.saving = false);
        }
      }
    },
    generateDescription(name) {
      this.saving = true;
      this.axios.post(`/api/gallery/description/${name}`)
        .then(_ => this.loadImages())
        .finally(() => this.saving = false);
    },
    clearThumbnails() {
      if (!this.saving) {
        const result = window.confirm(`Are you sure you want to clear the thumbnails?`);
        if (result) {
          this.saving = true;
          this.axios.delete(`/api/gallery/thumbnails`)
            .then(_ => this.loadImages())
            .finally(() => this.saving = false);
        }
      }
    },
    clearCache() {
      if (!this.saving) {
        const result = window.confirm(`Are you sure you want to clear the cache?`);
        if (result) {
          this.saving = true;
          this.axios.delete(`/api/gallery/cache`)
            .then(_ => this.loadImages())
            .finally(() => this.saving = false);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.has-margin-bottom {
  margin-bottom: 24px;
}

.table {
  img.image {
    max-width: 128px;
    max-height: 128px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
