<template>
  <div class="block">
    <div>
      <a :href="file.url" target="_blank">{{ file.name }}</a>&nbsp;
      <strong>({{ file.type }})</strong>
      <span v-if="file.description">&nbsp;{{ file.description }}</span>&nbsp;
      <span v-if="editable && loggedIn" @click="editFile" class="button icon is-primary">
        <i class="mdi mdi-square-edit-outline" />
      </span>
    </div>
    <div v-if="file.summary && loggedIn">
      <div class="ai-summary">{{ file.summary }}</div>
      <div class="ai-notice">
        <em>Note: This AI-generated summary is used for search (except private files) but not visible to the public.</em>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileItem",
  props: {
    file: {
      type: Object,
      required: true
    },
    loggedIn: {
      type: Boolean,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    editFile() {
      this.$emit('edit', this.file);
    }
  }
}
</script>

<style scoped>
a, a:hover, link, link:hover, .navbar-item, .navbar-link {
  color: #F88F26;

  &:hover {
    color: #a25123;
  }
}

.button {
  cursor: pointer;
}

.ai-summary {
  margin-top: 10px;
  white-space: pre-wrap;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
}

.ai-notice {
  font-size: 0.8em;
  color: gray;
  margin-top: 8px;
  margin-bottom: 10px;
}

</style>
