import { render, staticRenderFns } from "./ContentWrapper.vue?vue&type=template&id=24ecf1c8&scoped=true"
import script from "./ContentWrapper.vue?vue&type=script&lang=js"
export * from "./ContentWrapper.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ecf1c8",
  null
  
)

export default component.exports