<template>
	<div class="container">
		<div class="columns">
			<div class="column">
				<div class="title">{{title}}</div>

				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name: "ContentWrapper",
        props: {
            title: {type: String}
        }
    }
</script>

<style lang="scss" scoped>

</style>