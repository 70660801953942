<template>
	<content-wrapper title="Minutes">
		<files :show-type="true" :file-types="['Minutes','AnnualBoard','Other','Committee']" :logged-in="$parent.loggedIn"></files>

		<div class="archive">
			<h1 class="title">Archive</h1>
			<b-button data-test="show-archive-button" @click="showArchive = true" v-if="!showArchive">Show Archive</b-button>
			<b-collapse :open.sync="showArchive">
				<files :show-type="true" title="Minutes" :file-types="['Minutes','AnnualBoard','Other','Committee']" :show-archive="true" :logged-in="$parent.loggedIn"></files>
			</b-collapse>
		</div>
	</content-wrapper>
</template>

<script>
    import Files from "@/components/Files";
    import {required} from "vuelidate/lib/validators";
    import ContentWrapper from "@/components/ContentWrapper";

    export default {
        name: "Minutes",
        components: {Files, ContentWrapper},
        validations: {
            event: {
                name: {
                    required
                },
                date: {
                    required
                }
            }
        },
        methods: {
            getType(model) {
                return model.$error ? "is-danger" : "";
            },
            getMessage(model) {
                if (model.$error && model.required === false) {
                    return "This field is required";
                } else if (model.$error && model.email === false) {
                    return "Email invalid";
                } else if (model.$error) {
                    return "Value invalid";
                }
            }
        },
        data() {
            return {
                saving: false,
                loading: true,
                editing: false,
                showArchive: false
            }
        },
        mounted() {

        }
    }
</script>

<style lang="scss" scoped>
	@import "~vue-wysiwyg/dist/vueWysiwyg.css";

	.edit {
		cursor: pointer;
		color: #1d72aa;
	}

	.has-margin-top {
		margin-top: 10px;
	}

	.error {
		color: red;
		font-size: 14px;
	}

	.archive {
		margin-top: 20px;
	}
</style>
