<template>
  <content-wrapper title="Search">
    <div class="search">
      <form class="field has-addons" @submit.prevent="handleSearch(searchQuery)">
        <div class="control is-expanded">
          <b-input type="text" v-model="searchQuery"
                   icon="search-web"
                   icon-right="close-circle" :icon-right-clickable="true" @icon-right-click="clearSearch"
                   :loading="loading"/>
        </div>
        <div class="control">
          <b-button type="is-black" native-type="submit">Search</b-button>
        </div>
      </form>
    </div>
    <p>
      <div class="results" v-if="hasResults">
        <h2>Files</h2>
        <p>Showing results for: <strong>{{ resultsQuery }}</strong> (showing {{ searchResults.length }} of
          {{ totalResults }} items) </p>
        <div v-if="hasItems">
          <ul>
            <li v-for="file in searchResults" :key="file.id">
              <file-item :file="file" :logged-in="loggedIn"></file-item>
            </li>
          </ul>
        </div>
        <div v-else>
          No results found.
        </div>
      </div>
      <div v-else-if="searchError">
        <p class="has-text-danger">{{ searchError }}</p>
      </div>
      <div v-else>
        <p>Start typing to search for files.</p>
      </div>
      <div class="loading" v-if="loading">
        <b-loading :is-full-page="true" :active.sync="loading"/>
      </div>
    </p>
  </content-wrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper";
import Files from "@/components/Files";
import FileItem from "@/components/FileItem.vue";
import debounce from "buefy/src/utils/debounce";

export default {
  name: "Search",
  components: {
    ContentWrapper,
    Files,
    FileItem
  },
  methods: {
    handleSearch: debounce(function (query) {
      if (!query) {
        this.clearSearch();
        return;
      }
      this.loading = true;
      this.searchError = "";
      this.axios.get("/api/search", {params: {query}})
        .then(response => {
          this.searchResults = response.data.files;
          this.resultsQuery = response.data.query;
          this.totalResults = response.data.total;
          this.hasResults = true;
          this.hasItems = this.totalResults > 0;
        })
        .catch(error => {
          this.searchError = "An error occurred during the search.";
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }, 500),
    clearSearch() {
      this.searchQuery = "";
      this.resultsQuery = "";
      this.searchError = "";
      this.searchResults = [];
      this.totalResults = 0;
      this.hasResults = false;
      this.hasItems = false;
    }
  },
  data() {
    return {
      loading: false,
      hasItems: false,
      hasResults: false,
      searchError: "",
      searchQuery: "",
      resultsQuery: "",
      totalResults: 0,
      searchResults: []
    }
  },
  computed: {
    loggedIn() {
      return this.$parent.loggedIn;
    }
  },
  mounted() {
  },
  watch: {
    searchQuery(newQuery) {
      this.handleSearch(newQuery);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
